.overlay {
    width: 153vw;
    height: 194vh;
    position: fixed;
    top: 16%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    z-index: 999;
    font-size: 28px;
    padding: 20px;
    display: grid;
    place-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.35);
}
.overlayExit {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000000;
    color: #ffffff;
    z-index: 999;
    font-size: 30px;
    padding: 20px;
    display: grid;
    place-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: 600;
}
.highlight{
    color: #1d71fe;
}
.divlight{
    width: 600px;
    max-width: calc(100vw - 48px);
    margin-bottom: 16px;
    margin-inline-start: auto;
    padding: 26px 30px;
    overflow: hidden;
    line-height: 1.5714285714285714;
    word-wrap: break-word;
    background: #e8e8e8;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    position: absolute;
    color: #000000;
}
.spanTitlelight{
    font-size: 20px;
    padding-bottom: 15px;
}
.spanTextlight{
    font-size: 16px;
}
.notificationClass{
    background-color: #3a3a3a;
}
.ant-select-selection-item {
    color: #00c800 !important;
    text-decoration: underline;
}
.ant-select-selection-item ::after{
    color: #00c800;
}
.custom-dropdown {
    background-color: rgb(116 116 116) !important; /* 将 #your-color 替换为你想要的背景色 */
    color: #e8e8e8 !important;
    /*transform: translateX(50px);!* 通过 transform 控制向右移动 *!*/
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #00c800 !important; /* 将 #your-color 替换为你想要的背景色 */
    color: #e8e8e8;
}
