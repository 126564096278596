.bg-primary-dark {
  background-color: rgb(50, 50, 50);
}
.bg-primary-dark:hover {
  background-color: white;
  color: black;
}
/* 修改对话框的背景颜色 */
.ant-modal-content {
  background-color: #F5F4F4 !important; /* 灰色背景 */
}

/* 修改标题栏的背景颜色 */
。ant-modal {
  background-color: #666 !important; /* 灰色背景 */
  color: #fff; /* 文字颜色 */
}

/* 修改确认按钮的背景颜色 */
.ant-btn-primary {
  background-color: #666; /* 灰色背景 */
  border-color: #666; /* 边框颜色 */
  color: #fff; /* 文字颜色 */
}

/* 修改取消按钮的背景颜色 */
.ant-btn-default {
  background-color: #ccc; /* 浅灰色背景 */
  border-color: #9d9898; /* 边框颜色 */
  color: #333; /* 文字颜色 */
}
