input[type='text'] {
  -webkit-appearance: none;
}

.input-number:focus {
  border: none;
  outline: none;
}

.up-arrowsize svg {
  width: 7px;
  height: 7px;
}

.up-arrowsize svg path {
  fill: #726f7e;
}

.input-small {
  height: 26px;
}
