input[type='range'] {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: #5acce6;
}

input[type='range']::-moz-range-thumb {
  -webkit-appearance: none;
  border: none;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: #5acce6;
}

.input-range-thumb-design[type='range']::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.521);
  border: 1.5px solid #090c29;
}
