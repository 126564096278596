.customSelect__wrapper .customSelect__control {
  @apply bg-black border-primary-main shadow transition duration-300 border rounded w-full text-sm text-white leading-tight;
  min-height: 33px;
}
.customSelect__wrapper .customSelect__control:hover {
  @apply border-gray-500;
}

.customSelect__wrapper .customSelect__control:focus,
.customSelect__wrapper .customSelect__control--menu-is-open {
  @apply border-gray-500 outline-none;
}

.customSelect--is-disabled .customSelect__control--is-disabled {
  @apply pointer-events-none;
}

.customSelect__wrapper .customSelect__indicator-separator {
  @apply hidden;
}

.customSelect__wrapper .customSelect__dropdown-indicator {
  padding: 4px;
}

.customSelect__wrapper .customSelect__option {
  @apply flex flex-row items-center;
}

.customSelect__wrapper .customSelect__option:focus {
  @apply bg-primary-main;
}

.customSelect__wrapper .customSelect__option--is-selected {
  @apply bg-transparent;
}

.customSelect__wrapper .customSelect__single-value {
  @apply text-white;
}

.customSelect__wrapper.customSelect--is-disabled {
  @apply cursor-not-allowed pointer-events-auto;
}

.customSelect__wrapper .customSelect__value-container--is-multi {
  @apply px-3 py-2 inline-block truncate;
}

.customSelect__menu {
  background-color: transparent !important;
  border-radius: 6px;
}

.ohif-select .customSelect__menu-list {
  overflow-x: hidden !important;
  margin-right: 4px;
  margin-top: 4px;
  padding-top: 0px !important;
}

.ohif-select .customSelect__menu-list::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

.ohif-select .customSelect__menu-list::-webkit-scrollbar-thumb {
  background-color: #0944b3;
  border-radius: 10px;
}

.customSelect__option:focus {
  background-color: #0944b391 !important;
}

.ohif-select .customSelect__option, .ohif-select .customSelect__option label {
  color: #d6d6d6;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.ohif-select .customSelect__option:hover {
  background-color: #0944b391;
}

.ohif-select .customSelect__menu {
  border: 2px solid #0944b3;
  border-radius: 6px;
  background-color: #151515 !important;
}
